<template>
  <v-row>
    <v-col>
      <base-card>
        <trev-product-view-list :product="product"> </trev-product-view-list>
      </base-card>
    </v-col>
    <v-col>
      <v-row>
        <v-col>
          <base-card>
            <v-card-title>
              <span class="text-h6"> Suggested Price </span>
            </v-card-title>
            <v-card-text>
              <span class="text-h4">
                <span class="success--text">
                  £
                  <trev-number-ticker
                    :number="suggestedPrice"
                  ></trev-number-ticker>
                </span>
              </span>
            </v-card-text>
          </base-card>
        </v-col>
        <v-col>
          <base-card>
            <v-card-title>
              <span class="text-h6"> Old Contract Price </span>
            </v-card-title>
            <v-card-text>
              <span class="text-h4">
                <span class="success--text">
                  £<trev-number-ticker
                    :number="contract.price"
                  ></trev-number-ticker>
                </span>
              </span>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <base-card class="mt-4">
            <v-card-title>
              <span class="text-h3"> Final Contract Price </span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                prepend-inner-icon="mdi-currency-gbp"
                v-model="innerPrice"
                @change="$emit('update:price', Number(innerPrice))"
                outlined
                color="success"
                type="number"
              >
              </v-text-field>
            </v-card-text>
          </base-card>
          <base-card>
            <v-card-title> Change Quantity </v-card-title>
            <v-card-text>
              <v-text-field
                @change="$emit('update:productQuantity', Number(innerQuantity))"
                type="number"
                v-model="innerQuantity"
              >
              </v-text-field>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import TrevProductViewList from "../products/trev-product-view-list.vue";
import * as prodUtils from "../../utilities/Object Utilities/Products";
import TrevNumberTicker from "../ui/trev-number-ticker.vue";
export default {
  components: {
    TrevProductViewList,
    TrevNumberTicker,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    price: {
      type: Number,
      default: 0,
    },
    productQuantity: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    suggestedPrice() {
      if (
        this.innerQuantity &&
        this.product &&
        this.product.price &&
        this.productPriceRules &&
        this.productPriceRules.length > 0
      ) {
        return prodUtils.calculatePriceFromRules(
          this.innerQuantity,
          this.product.price,
          this.productPriceRules
        );
      } else {
        return 0;
      }
    },
  },
  created() {
    this.getContract().then((contract) => {
      this.contract = contract;
      this.innerPrice = contract.price;
    });

    this.getOtherProducts().then((prodResponse) => {
      this.products = prodResponse.payload;
    });

    this.getProductPriceRules().then((priceRulesResp) => {
      this.productPriceRules = priceRulesResp.payload;
    });
  },
  methods: {
    async getProductPriceRules() {
      return await this.$courier.Products.withId(
        this.product.id
      ).PriceRules.getData(1, 9999, "");
    },
    async getOtherProducts() {
      return await this.$courier.Contracts.withId(
        this.product.parentContractId
      ).Products.getData(1, 9999, "");
    },
    async getContract() {
      return await this.$courier.Contracts.getById(
        this.product.parentContractId
      );
    },
  },
  data() {
    return {
      contract: {},
      products: [],
      innerQuantity: this.productQuantity,
      productPriceRules: [],
      innerPrice: 0,
    };
  },
};
</script>

<style>
</style>