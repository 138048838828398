var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-toolbar-title',[_vm._v(" Contract Details ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.$emit('edit-clicked')}}},[_vm._v(" Edit "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-pencil ")])],1)],1),_c('v-list',[(_vm.contract.invoiceNumber)?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.contract.invoiceNumber)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Invoice Number ")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" £"+_vm._s(_vm.contract.price)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Price ")])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.contract.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Name ")])],1)],1),(_vm.contract.description)?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.contract.description)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Description ")])],1)],1):_vm._e(),(
        _vm.contract.effectiveDate &&
        new Date(_vm.contract.effectiveDate).getFullYear() > 1
      )?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(new Date(_vm.contract.effectiveDate).toLocaleDateString())+" ")]),_c('v-list-item-subtitle',[_vm._v(" Effective Date ")])],1)],1):_vm._e(),(
        _vm.contract.expiryDate && new Date(_vm.contract.expiryDate).getFullYear() > 1
      )?_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(new Date(_vm.contract.expiryDate).toLocaleDateString())+" ")]),_c('v-list-item-subtitle',[_vm._v(" Expiry Date ")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.parentCompany.name)+" "),(_vm.parentCompany.id)?_c('v-btn',{attrs:{"icon":"","to":{
              name: 'Company Dashboard',
              params: { companyId: _vm.parentCompany.id },
            },"color":"primary"}},[_c('v-icon',[_vm._v(" mdi-link ")])],1):_vm._e()],1),_c('v-list-item-subtitle',[_vm._v(" Company ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }