<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-toolbar-title> Contract Details </v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-btn small color="warning" @click="$emit('edit-clicked')">
        Edit <v-icon class="ml-2"> mdi-pencil </v-icon>
      </v-btn>
    </v-toolbar>
    <v-list>
      <v-list-item two-line v-if="contract.invoiceNumber">
        <v-list-item-content>
          <v-list-item-title>
            {{ contract.invoiceNumber }}
          </v-list-item-title>
          <v-list-item-subtitle> Invoice Number </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title> £{{ contract.price }} </v-list-item-title>
          <v-list-item-subtitle> Price </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>
            {{ contract.name }}
          </v-list-item-title>
          <v-list-item-subtitle> Name </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line v-if="contract.description">
        <v-list-item-content>
          <v-list-item-title>
            {{ contract.description }}
          </v-list-item-title>
          <v-list-item-subtitle> Description </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        two-line
        v-if="
          contract.effectiveDate &&
          new Date(contract.effectiveDate).getFullYear() > 1
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ new Date(contract.effectiveDate).toLocaleDateString() }}
          </v-list-item-title>
          <v-list-item-subtitle> Effective Date </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        two-line
        v-if="
          contract.expiryDate && new Date(contract.expiryDate).getFullYear() > 1
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ new Date(contract.expiryDate).toLocaleDateString() }}
          </v-list-item-title>
          <v-list-item-subtitle> Expiry Date </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>
            {{ parentCompany.name }}
            <v-btn
              v-if="parentCompany.id"
              icon
              :to="{
                name: 'Company Dashboard',
                params: { companyId: parentCompany.id },
              }"
              color="primary"
            >
              <v-icon> mdi-link </v-icon>
            </v-btn>
          </v-list-item-title>
          <v-list-item-subtitle> Company </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    parentCompany: {
        type: Object,
        default: () => {}
    },
    contract: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
</style>