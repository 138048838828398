import * as Courier from '../Courier';
import * as productUtils from './Products'

const courier = new Courier.Courier();

export const saveContract = async (productsToSave, contractId) => {
    productsToSave.forEach((x) => {
        if (x.purchaseDate === "") {
            delete x.purchaseDate;
        }
    });

    var allNewSkeletons = productsToSave.filter(
        (x) => x.type === 'global' || x.type === 'custom'
    );
    var allExistingProducts = productsToSave.filter(
        (x) => x.type === "Existing"
    );

    var allInContractProducts = productsToSave.filter(
        (x) => x.type === "In Contract"
    );

    //need to loop through in contract products, if there are some with the same sku merge them
    
    for (const product of allInContractProducts) {
        //search allNewSkeletons - for occurence of this skuNumber - if so merge quantities and remove from list
        if (allNewSkeletons.some((x) => x.skuNumber === product.skuNumber)) {
            //there is a new skeleton with the same sku as something that exists in contract
            var sumOfNewQuantity = allNewSkeletons.filter(x => x.skuNumber === product.skuNumber)
                .map((x) => x.quantity)
                .reduce((partialSum, x) => partialSum + x);

            product.quantity = Number(product.quantity) + Number(sumOfNewQuantity);

            //now remove the duplicate sku's in allNewSkeletons so doesnt process twice
            allNewSkeletons = allNewSkeletons.filter(
                (x) => x.skuNumber !== product.skuNumber
            );
        }

        //search allExisting - for occurence of this skuNumber - if so merge quantities and remove from list
        if (
            allExistingProducts.some((x) => x.skuNumber === product.skuNumber)
        ) {
            var sumOfNewQuantity = allExistingProducts.filter(x => x.skuNumber === product.skuNumber)
                .map((x) => x.quantity)
                .reduce((partialSum, x) => partialSum + x);
            product.quantity = Number(product.quantity) + Number(sumOfNewQuantity);

            //remove existing sku's in existing products
            allExistingProducts = allExistingProducts.filter(
                (x) => x.skuNumber !== product.skuNumber
            );
        }
    }

    //loop through the products, if its an existing one, then update to have parent contract id of new one, if not existing, just add
    for (const product of allExistingProducts) {
        product.parentContractId = contractId;
        product.dateAdded = new Date().toISOString();
        //get the difference of quantity and set that as a duplicate product
        var newProduct = await courier.Products.getById(product.id);

        //use this as a product to duplicate for new one with updated quantity
        var replicated = JSON.parse(JSON.stringify(newProduct));
        delete replicated.id;
        delete replicated.type;

        if (product.quantity < replicated.quantity) {
            //need to add seperate product with new diff quantity subtract from contract quantity

            var metaData = [];
            var priceRules = [];

            metaData = await courier.Products.withId(
                product.id
            ).MetaData.getData(1, 99999, "");

            if (metaData) {
                metaData.payload.forEach((m) => {
                    delete m.id;
                });
            }

            replicated.metaData = metaData.payload;

            //get the priceRules
            priceRules = await courier.Products.withId(
                product.id
            ).PriceRules.getData(1, 99999, "");
            if (priceRules) {
                priceRules.payload.forEach((p) => {
                    delete p.id;
                });
            }

            //this is the product which is the new one NOT in the contract

            replicated.priceRules = priceRules.payload;
            replicated.quantity = replicated.quantity - product.quantity;
            delete replicated.parentContractId;
            replicated.dateAdded = new Date().toISOString();
            replicated.totalCost = productUtils.calculatePriceFromRules(
                replicated.quantity,
                replicated.price,
                replicated.priceRules
            );

            //add the new product into system

            courier.Products.add(replicated);

            product.totalCost = productUtils.calculatePriceFromRules(
                product.quantity,
                product.price,
                product.priceRules
            );

            courier.Products.updateById(product.id, product);
        } else {
            //in this case the contract existing product has either same or greater quantity to existing
            // need to update existing product with new quantity and total cost
            // quantity is done already, just call cost update and commit

            product.totalCost = productUtils.calculatePriceFromRules(
                product.quantity,
                product.price,
                product.priceRules
            );

            courier.Products.updateById(product.id, product);
        }
    }

    allNewSkeletons.forEach((x) => {
        courier.Contracts.withId(contractId)
            .Products.add(x)
            .then(() => {
                //idk show done?
            });
    });

    var inContractTasks = [];

    for (const product of allInContractProducts) {
        //update if quantity is more than 0 else delete if is
        if (product.quantity === 0) {
            var task = courier.Products.removeById(product.id, product);
            inContractTasks.push(task);
        } else {
            var task = courier.Products.updateById(product.id, product);
            inContractTasks.push(task);
        }
    }

    return Promise.resolve();
}