<template>
  <v-stepper v-model="currentStep">
    <v-stepper-header>
      <v-stepper-step step="1" :complete="currentStep > 1">
        Select Products
      </v-stepper-step>
      <v-divider></v-divider>
      <template v-for="(prod, index) in selectedProducts">
        <v-stepper-step
          :key="index + '-product'"
          :complete="currentStep > 1 + index"
          :step="(2 + index).toString()"
        >
          Customise: <span class="success--text">{{ prod.name }}</span>
        </v-stepper-step>
        <v-divider :key="index"></v-divider>
      </template>
      <v-stepper-step
        :step="2 + selectedProducts.length"
        :complete="currentStep > selectedProducts.length + 1"
      >
        Price Settings
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <trev-contract-product-selection
          :products.sync="selectedProducts"
          :companyId="companyId"
        ></trev-contract-product-selection>
        <v-btn
          @click="selectedAllProducts"
          v-if="selectedProducts.length > 0"
          class="float-right"
          color="primary"
        >
          Next
        </v-btn>
      </v-stepper-content>
      <v-stepper-content
        :step="(2 + index).toString()"
        v-for="(product, index) in selectedProducts"
        :key="product.id"
      >
        <trev-product-customise
          v-if="product.type != 'Existing'"
          :productSkeleton="product"
          @product-updated="customisedProductUpdated($event, index)"
          @products-are-equal="
            $set(selectedProducts[index], 'isCustomised', false);
            $forceUpdate();
          "
          @products-are-not-equal="
            $set(selectedProducts[index], 'isCustomised', true);
            $forceUpdate();
          "
        >
        </trev-product-customise>
        <trev-contract-existing-product-edit :product="product" v-else>
        </trev-contract-existing-product-edit>
        <v-btn
          color="primary"
          class="float-right"
          @click="productCustomseNext(product)"
        >
          <span v-show="selectedProducts[index].isCustomised">
            Continue with changes
          </span>
          <span v-show="!selectedProducts[index].isCustomised">
            Continue without making changes
          </span>
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>

        <v-btn text @click="currentStep--" class="float-left">
          <v-icon> mdi-chevron-left </v-icon>
          Previous
        </v-btn>
      </v-stepper-content>
      <v-stepper-content :step="selectedProducts.length + 2">
        <trev-contract-pricing-settings
          :price.sync="finalCost"
          :products="finalisedProducts"
        >
        </trev-contract-pricing-settings>
        <v-btn text class="float-left" @click="currentStep--"> Previous </v-btn>
        <v-btn color="primary" @click="saveContract" class="float-right">
          Save changes
          <v-icon class="ml-2"> mdi-floppy </v-icon>
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import TrevProductCustomise from "../products/trev-product-customise.vue";
import TrevContractExistingProductEdit from "./forms/trev-contract-existing-product-edit.vue";
import TrevContractPricingSettings from "./forms/trev-contract-pricing-settings.vue";
import trevContractProductSelection from "./forms/trev-contract-product-selection.vue";
import * as contractUtils from "../../utilities/Object Utilities/Contracts";
export default {
  components: {
    trevContractProductSelection,
    TrevContractExistingProductEdit,
    TrevProductCustomise,
    TrevContractPricingSettings,
  },
  props: {
    companyId: {
      type: String,
      default: "",
    },
    contract: {
      type: Object,
      default: () => {},
    },
    inContractProducts: {
      type: [],
      default: () => {},
    },
  },
  methods: {
    saveContract() {
      contractUtils
        .saveContract(this.finalisedProducts, this.contract.id)
        .then(() => {
          var tempContract = JSON.parse(JSON.stringify(this.contract));
          tempContract.price = this.finalCost;
          this.$courier.Contracts.updateById(this.contract.id, tempContract);

          this.customisedProducts = [];
          this.selectedProducts = [];
          this.finalisedProducts = [];

          this.$emit("saved-contract-products");
          this.currentStep = 1;
        });
    },
    productCustomseNext(prodSkel) {
      if (this.currentStep - 1 >= this.selectedProducts.length) {
        var products = JSON.parse(JSON.stringify(this.customisedProducts));

        products.forEach((product) => {
          if (product.type !== "Existing") {
            delete product.id;
          }

          if (product.metaData) {
            product.metaData.forEach((metaDataItem) => {
              delete metaDataItem.id;
            });
          }

          if (product.priceRules) {
            product.priceRules.forEach((priceRule) => {
              delete priceRule.id;
            });
          }

          product.expiryDate = this.contract.expiryDate;
          product.purchaseDate = this.contract.effectiveDate;
          if (!product.quantity || product.quantity <= 1) {
            product.quantity = 1;
          }

          product.parentCompanyId = this.companyId;
        });

        if (this.inContractProducts && this.inContractProducts.length > 0) {
          this.inContractProducts.forEach((contractProduct) => {
            if (
              !this.finalisedProducts.find((x) => x.id === contractProduct.id)
            ) {
              contractProduct.type = "In Contract";
              this.finalisedProducts.push(contractProduct);
            }
          });
        }

        if (products && products.length > 0) {
          for (const product of products) {
            this.finalisedProducts.push(product);
          }
        }
      }
      this.currentStep++;
    },
    customisedProductUpdated(customProduct, index) {
      this.customisedProducts[index] = customProduct;
    },
    selectedAllProducts() {
      this.selectedProducts.forEach((product) => {
        var priceRulePromise = this.$courier.ProductSkeletons.withId(
          product.id
        ).PriceRules.getData(1, 999, "");
        var metaDataPromise = this.$courier.ProductSkeletons.withId(
          product.id
        ).MetaData.getData(1, 999, "");

        Promise.all([priceRulePromise, metaDataPromise]).then((promiseData) => {
          product.priceRules = promiseData[0].payload;
          product.metaData = promiseData[1].payload;
        });
      });

      this.customisedProducts = JSON.parse(
        JSON.stringify(this.selectedProducts)
      );

      this.currentStep++;
    },
    cancel(){
      this.currentStep = 1;
      this.selectedProducts = [];
      this.customisedProducts = [];
      this.finalisedProducts = [];
      this.finalCost = this.contract.price;
      this.suggestedCost = 0;
      this.inContractProductsInner = [];
    }
  },
  data() {
    return {
      currentStep: 1,
      selectedProducts: [],
      customisedProducts: [],
      finalisedProducts: [],
      finalCost: this.contract.price,
      suggestedCost: 0,
      inContractProductsInner: [],
    };
  },
};
</script>

<style>
</style>