<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card :loading="contract === {}">
          <v-toolbar elevation="0">
            <v-toolbar-title>
              <span class="mr-6">
                <v-icon class="mr-2"> mdi-file-sign </v-icon>
                {{ contract.name }}
                <span class="ml-4" v-if="contract.invoiceNumber">
                  INV:
                  <span class="primary--text">
                    {{ contract.invoiceNumber }}
                  </span>
                </span>
                <span class="ml-4">
                  Price:
                  <span class="success--text"> £{{ contract.price }} </span>
                </span>
              </span>
            </v-toolbar-title>
            <span class="muted--text">
              <span
                v-if="
                  contract.expiryDate &&
                  new Date(contract.expiryDate).getFullYear() > 1
                "
                class="mr-5"
              >
                Expires:
                <span class="danger--text">{{
                  new Date(contract.expiryDate).toLocaleDateString()
                }}</span>
              </span>
            </span>
            <v-spacer></v-spacer>
            <v-btn @click="showAlerting = true" color="purple">
              Alerting
              <v-icon class="ml-2"> mdi-alert-decagram-outline </v-icon>
            </v-btn>
            <v-btn @click="showBreakClause = true" color="indigo" class="ml-2">
              Break Clause
              <v-icon class="ml-2"> mdi-glass-fragile </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3" sm="3" xs="12">
        <v-card
          :loading="contract == {} && parentCompany == {}"
          v-if="contract == {} && parentCompany == {}"
        >
        </v-card>
        <trev-contract-details-card
          @edit-clicked="showEditDetails = true"
          :contract="contract"
          :parentCompany="parentCompany"
          v-else
        ></trev-contract-details-card>
      </v-col>
      <v-col>
        <trev-cool-table
          :tableId="'contract-products'"
          @add-clicked="showEditProducts = true"
          title="Products"
          :showAddLinkAsButton="true"
          :courier="
            $courier.Contracts.withId($route.params.contractId).Products
          "
          :headers="productHeaders"
        >
        <template v-slot:item.name="{item}">
          <v-chip label>
            {{item.name}}
          </v-chip>
        </template>
          <template v-slot:item.skuNumber="{ item }">            
              {{ item.skuNumber }}            
          </template>
          <template v-slot:item.quantity="{ item }">
            <v-chip>
              {{ item.quantity }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="primary"
              small
              class="mr-2"
              @click="
                productToChangeQuantity = item;
                showChangeProductQuantity = true;
                editingProductQuantity = item.quantity;
              "
            >
              Change Quantity
              <v-icon class="ml-2"> mdi-counter </v-icon>
            </v-btn>
            <v-btn
              color="danger"
              class="white--text"
              small
              @click="
                showAreYouSureDeleteProduct = true;
                productToRemove = item;
              "
            >
              Remove <v-icon class="ml-2"> mdi-delete </v-icon>
            </v-btn>
          </template>
        </trev-cool-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showEditProducts"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title> Change Contractual Products </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              $refs.productAdd.cancel();
              showEditProducts = false;
            "
            text
          >
            Cancel
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col>
              <trev-contract-products-add
                ref="productAdd"
                v-if="contract.id && parentCompany.id"
                :companyId="parentCompany.id"
                :contract="contract"
                :inContractProducts="contractProducts"
                @saved-contract-products="savedNewProducts"
              >
              </trev-contract-products-add>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditDetails" width="60vh">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title> Contract Details </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelEditDetails"> Cancel </v-btn>
          <v-btn
            v-if="isDetailsDifferent"
            color="primary"
            @click="showAreYouSureDetails = true"
          >
            Save <v-icon class="ml-2"> mdi-floppy </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field label="Name" v-model="editingContract.name">
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Invoice Number"
                v-model="editingContract.invoiceNumber"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <dialog-date-picker
                :uniqueId="'view-contract-edit-effective-date'"
                :date.sync="editingContract.effectiveDate"
                dialogTitle="Effective Date"
                :hideTitle="true"
              ></dialog-date-picker>
            </v-col>
            <v-col>
              <dialog-date-picker
                :uniqueId="'view-contract-edit-expiry-date'"
                :date.sync="editingContract.expiryDate"
                dialogTitle="Expiry Date"
                :hideTitle="true"
              ></dialog-date-picker>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditBreakClause" width="60vh">
      <v-card>
        <v-toolbar>
          <v-toolbar-title> Edit Break Clause </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelEditBreakClause"> Cancel </v-btn>
          <v-btn
            v-if="isBreakClauseDifferent"
            color="primary"
            @click="showAreYouSureBreakClause = true"
          >
            Save <v-icon class="ml-2"> mdi-floppy </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <dialog-date-picker :date.sync="editingContract.effectiveDate" :hideTitle="true">
          </dialog-date-picker>
          <trev-contract-break-clause
            :breakClauseInDays.sync="
              editingContract.breakClauseInDaysFromEffectiveDate
            "
            :effectiveDate.sync="editingContract.effectiveDate"
            :hasBreakClause.sync="editingContract.hasBreakClause"
          >
          </trev-contract-break-clause>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showBreakClause" width="60vh">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title> Break Clause </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            @click="
              showBreakClause = false;
              showEditBreakClause = true;
            "
          >
            Edit
            <v-icon class="ml-2"> mdi-pencil </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="contract.hasBreakClause" class="primary--text">
                    Has
                  </span>
                  <span v-else class="danger--text"> Has No </span>
                  Break Clause
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="contract.hasBreakClause">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="primary--text">{{
                    contract.breakClauseInDaysFromEffectiveDate
                  }}</span>
                  Days From
                  <span class="primary--text">{{
                    contract.effectiveDate
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAlerting" width="60vh">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title> Alerting </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              showAlerting = false;
              showEditAlerting = true;
            "
            color="warning"
          >
            Edit
            <v-icon class="ml-2"> mdi-pencil </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="contract.hasAlerts" class="primary--text">
                    Has
                  </span>
                  <span v-else class="danger--text"> Has No </span>
                  Alerting
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="contract.hasAlerts">
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="contract.alertGroup">
                    {{ contract.alertGroup }}
                  </span>
                  <span v-else> None Set </span>
                </v-list-item-title>
                <v-list-item-subtitle> Alert Group </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line v-if="contract.hasAlerts">
              <v-list-item-content>
                <v-list-item-title>
                  {{ contract.alertOffsetInDays }} Days Before
                  {{ new Date(contract.expiryDate).toLocaleDateString() }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Alert Offset In Days
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditAlerting" width="60vh">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title> Alerting </v-toolbar-title>
          <v-spacer> </v-spacer>
          <v-btn
            @click="showAreYouSureAlerting = true"
            color="primary"
            v-if="isAlertingDifferent"
          >
            Save
            <v-icon class="ml-2"> mdi-floppy </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <trev-product-alert-settings
            :alertGroup.sync="editingContract.alertGroup"
            :alertOffSetInDays.sync="editingContract.alertOffsetInDays"
            :hasAlert.sync="editingContract.hasAlerts"
          >
          </trev-product-alert-settings>
        </v-card-text>
      </v-card>
    </v-dialog>
    <trev-confirm-delete-dialog
      :itemToDelete="productToRemove.name"
      :maxWidth="600"
      :show="showAreYouSureDeleteProduct"
      @dialog-delete-deleted="removeProductFromContract"
      @dialog-delete-cancelled="
        productToRemove = {};
        showAreYouSureDeleteProduct = false;
      "
    >
    </trev-confirm-delete-dialog>
    <v-dialog v-model="showChangeProductQuantity" max-width="100vh">
      <v-card>
        <v-toolbar>
          <v-toolbar-title> Change Product Quantity </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <trev-contract-product-change
            :price.sync="editingContractPrice"
            :product.sync="productToChangeQuantity"
            :productQuantity.sync="editingProductQuantity"
          ></trev-contract-product-change>
        </v-card-text>
        <v-card-text>
          <v-btn text> Cancl </v-btn>
          <v-btn
            class="float-right"
            color="primary"
            @click="saveProductQuantityChange"
          >
            Save Changes <v-icon class="ml-2">mdi-floppy</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAreYouSureBreakClause" max-width="50vh">
      <v-card>
        <v-card-title>
          Are you sure you want to save changes?
        </v-card-title>
        <v-card-actions>
          <v-btn text @click="cancelEditBreakClause">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveBreakClauseDetails">
            Yes
            <v-icon class="ml-2">
              mdi-floppy
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAreYouSureDetails" max-width="50vh">
      <v-card>
        <v-card-title> Are you sure you want to save changes? </v-card-title>
        <v-card-actions>
          <v-btn text @click="cancelEditDetails = false"> Cancel </v-btn>
          <v-btn color="primary" @click="saveContractDetails">
            Yes <v-icon class="ml-2"> mdi-floppy </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAreYouSureAlerting" max-width="50vh">
      <v-card>
        <v-card-title> Are you sure you want to save changes? </v-card-title>
        <v-card-actions>
          <v-btn text @click="showAreYouSureAlerting = false"> Cancel </v-btn>
          <v-btn color="primary" @click="saveAlerting">
            Yes <v-icon class="ml-2"> mdi-floppy </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TrevConfirmDeleteDialog from "../../../../components/base/dialog/trev-confirm-delete-dialog.vue";
import DialogDatePicker from "../../../../components/base/dialogDatePicker.vue";
import TrevContractBreakClause from "../../../../components/contracts/forms/trev-contract-break-clause.vue";
import TrevContractProductChange from "../../../../components/contracts/trev-contract-product-change.vue";
import TrevContractProductsAdd from "../../../../components/contracts/trev-contract-products-add.vue";
import TrevProductAlertSettings from "../../../../components/products/forms/trev-product-alert-settings.vue";
import TrevCoolTable from "../../../../components/tables/trev-cool-table.vue";
import TrevContractDetailsCard from "./Components/trev-contract-details-card.vue";
export default {
  components: {
    TrevProductAlertSettings,
    DialogDatePicker,
    TrevCoolTable,
    TrevContractProductsAdd,
    TrevConfirmDeleteDialog,
    TrevContractProductChange,
    TrevContractDetailsCard,
    TrevContractBreakClause,
  },
  computed: {
    isDetailsDifferent() {
      if (
        this.contract.name != this.editingContract.name ||
        this.contract.invoiceNumber != this.editingContract.invoiceNumber ||
        this.contract.effectiveDate != this.editingContract.effectiveDate ||
        this.contract.expiryDate != this.editingContract.expiryDate
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAlertingDifferent() {
      return (
        this.contract.hasAlerts != this.editingContract.hasAlerts ||
        this.contract.alertGroup != this.editingContract.alertGroup ||
        this.contract.alertOffsetInDays !=
          this.editingContract.alertOffsetInDays
      );
    },
    isBreakClauseDifferent() {
      return (
        this.contract.hasBreakClause != this.editingContract.hasBreakClause ||
        this.contract.breakClauseInDaysFromEffectiveDate !=
          this.editingContract.breakClauseInDaysFromEffectiveDate
      );
    },
  },
  async created() {
    this.getContract();

    this.getContractProducts();
  },
  methods: {
    async saveProductQuantityChange() {
      this.productToChangeQuantity.quantity = this.editingProductQuantity;
      //save the new product quantity change
      await this.$courier.Products.updateById(
        this.productToChangeQuantity.id,
        this.productToChangeQuantity
      );
      //save the new contract price
      var contractObj = JSON.parse(JSON.stringify(this.contract));
      contractObj.price = this.editingContractPrice;
      await this.$courier.Contracts.updateById(this.contract.id, contractObj);

      this.editingContractPrice = 0;
      this.productToChangeQuantity = {};
      this.showChangeProductQuantity = false;
      this.getContractProducts();
      this.$eventBus.$emit("contract-products-refresh");
      this.getContract();
    },
    async removeProductFromContract() {
      try {
        await this.$courier.Products.removeById(this.productToRemove.id);
        this.productToRemove = {};
        this.showAreYouSureDeleteProduct = false;
        this.getContractProducts();
        this.$eventBus.$emit("contract-products-refresh");
      } catch (exception) {
        //idk do something
        console.log(exception);
      }
    },
    async savedNewProducts() {
      await this.getContractProducts();
      this.getContract().then(() => {
        this.$eventBus.$emit("contract-products-refresh");
      });
      //refresh product table
    },
    async getContractProducts() {
      var prods = await this.$courier.Contracts.withId(
        this.$route.params.contractId
      ).Products.getData(1, 9999, "");

      this.contractProducts = prods.payload;
    },
    async getContract() {
      var contract = await this.$courier.Contracts.getById(
        this.$route.params.contractId
      );

      this.contract = contract;
      this.editingContractPrice = JSON.parse(
        JSON.stringify(this.contract)
      ).price;

      this.editingContract = JSON.parse(JSON.stringify(this.contract));

      var comp = await this.getParentCompany();
      this.parentCompany = comp;
    },
    async getParentCompany() {
      return await this.$courier.Companies.getById(
        this.contract.parentCompanyId
      );
    },
    cancelEditBreakClause() {
      this.editingContract.hasBreakClause = this.contract.hasBreakClause;
      this.editingContract.breakClauseInDaysFromEffectiveDate =
        this.contract.breakClauseInDaysFromEffectiveDate;
      this.showEditBreakClause = false;
      this.showAreYouSureBreakClause = false;
    },
    cancelEditDetails() {
      this.editingContract.name = this.contract.name;
      this.editingContract.effectiveDate = this.contract.effectiveDate;
      this.editingContract.expiryDate = this.contract.expiryDate;
      this.showEditDetails = false;
    },
    async saveContractDetails() {
      try {
        await this.$courier.Contracts.updateById(
          this.editingContract.id,
          this.editingContract
        );
        this.contract.invoiceNumber = this.editingContract.invoiceNumber;
        this.contract.name = this.editingContract.name;
        this.contract.expiryDate = this.editingContract.expiryDate;
        this.contract.effectiveDate = this.editingContract.effectiveDate;
        this.showAreYouSureDetails = false;
        this.showEditDetails = false;
      } catch (exception) {
        console.log(exception); //something fucked up
      }
    },
    async saveBreakClauseDetails(){
      try{
        await this.$courier.Contracts.updateById(this.editingContract.id, this.editingContract);

        this.contract.effectiveDate = this.editingContract.effectiveDate;
        this.contract.hasBreakClause = this.editingContract.hasBreakClause;
        this.contract.breakClauseInDaysFromEffectiveDate = this.editingContract.breakClauseInDaysFromEffectiveDate;

        this.showAreYouSureBreakClause = false;
        this.showEditBreakClause = false;
      }
      catch(exception)
      {
        console.log(exception); //iut broke log it?
      }
    },
    async saveAlerting() {
      try {
        if (!this.editingContract.hasAlerts) {
          this.editingContract.alertGroup = "";
          this.editingContract.alertOffsetInDays = 0;
        }

        await this.$courier.Contracts.updateById(
          this.editingContract.id,
          this.editingContract
        );
        this.contract.alertGroup = this.editingContract.alertGroup;
        this.contract.alertOffsetInDays =
          this.editingContract.alertOffsetInDays;
        this.contract.hasAlerts = this.editingContract.hasAlerts;
        this.showAreYouSureAlerting = false;
      } catch (exception) {
        console.log(exception);
        //idk what to do here
      }
    },
  },
  data() {
    return {
      contract: {},
      contractProducts: [],
      editingContractPrice: 0,
      editingProductQuantity: 0,
      editingContract: {},
      parentCompany: {},
      showAreYouSureDetails: false,
      showAreYouSureAlerting: false,
      showEditDetails: false,
      showEditAlerting: false,
      showEditBreakClause: false,
      showAlerting: false,
      showBreakClause: false,
      showEditProducts: false,
      showAreYouSureDeleteProduct: false,
      showAreYouSureBreakClause: false,
      productToRemove: {},
      productToChangeQuantity: {},
      showChangeProductQuantity: false,
      productHeaders: [
        {
          text: "Name",
          value: "name",
        },
        {
          name: 'Sku',
          value: 'skuNumber'
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Division",
          value: "division",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style>
</style>